import * as React from "react";
import { basePageWrap } from "../BasePage";
import SEO from "components/seo";
import Streamfield from "components/streamfield";
import Hero from "components/hero";

const HomePage = ({ title, collections, subtitle, urlPath, body, site, ...props }) => {
  return (
    <>
      <SEO
        title={props.seoTitle || title}
        site={site}
        description={props.searchDescription || props.subtitle}
      />
      <Hero title={title} subtitle={subtitle} isHome={true} />
      <Streamfield streamfield={body} collections={collections} />
    </>
  );
};

export default basePageWrap(HomePage);
